import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import * as Yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
  FormFeedback,
} from "reactstrap"

import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

import { createSelector } from "reselect"
import Proptypes from "prop-types"
import withRouter from "components/Common/withRouter"
import {
  addNewInvoice,
  addNewProposal,
  getExpenseList,
  getGstRateList,
  getProjectCompanyDetail,
  getProjectList,
  getProposalsType,
  getTicketDetail,
  getTicketList,
} from "store/actions"
import { getExternalCompanyByProject } from "helpers/backend"

const AddProposal = props => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [clientId, setClientId] = useState()
  const [unitId, setUnitId] = useState()
  const BillingState = state => state.billAndPayments
  const ProposalState = state => state.proposals
  const path = useLocation()

  const AllBillingProperties = createSelector(
    BillingState,
    ProposalState,
    (billing, proposal) => ({
      projectList: billing.projectList.data,
      gstRateList: billing.gstRateList.data,
      proposalTypeList: proposal.proposalType.data,
    })
  )

  const { projectList, gstRateList, proposalTypeList } =
    useSelector(AllBillingProperties)

  useEffect(() => {
    dispatch(getGstRateList())
    dispatch(getProposalsType())
  }, [dispatch])

  //FORMIK VALIDATION
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      project_id: "",
      company_id: "",
      proposal_date: new Date().toISOString().split("T")[0] || "",
      vendor_id: "",
      proposal_no: "",
      proposal_type_id: "",
      project_id: "",
      proposal_title: "",
      proposal_file_path: "",
      amount: "",
      taxable_amount: "",
      gst_percentage: "",
    },
    validationSchema: Yup.object().shape({
      project_id: Yup.number().required("Please Select Project"),
      proposal_date: Yup.string().required("Proposal date is required"),
      proposal_no: Yup.string().required("Proposal number is required"),
      proposal_type_id: Yup.string().required("Proposal type is required"),
      proposal_title: Yup.string().required("Proposal title is required"),
      taxable_amount: Yup.number()
        .positive()
        .typeError("Taxable Amount must be a positive number")
        .required("Taxable Amount is required"),
      // proposal_file_path: Yup.mixed().required("Proposal File is required"),
    }),

    onSubmit: async values => {
      dispatch(addNewProposal(props.router.navigate, values))
    },
  })

  useEffect(() => {
    if (user) {
      dispatch(getProjectList(user.id))
      validation.setFieldValue("vendor_id", user.id)
    }
  }, [user])

  useEffect(() => {
    if (validation.values.company_id) {
      dispatch(getProjectCompanyDetail(validation.values.company_id))
    }
  }, [validation.values.company_id])
  useEffect(() => {
    if (validation.values.is_service_request) {
      validation.setFieldValue("service_ticket_id", "")
    }
  }, [validation.values.is_service_request])

  useEffect(() => {
    if (validation.values.project_id && user) {
      const value = {
        project_id: validation.values.project_id,
        vendor_id: user.id,
      }
      dispatch(getTicketList(value))
    }
  }, [validation.values.project_id, user])

  useEffect(() => {
    dispatch(getGstRateList())
  }, [dispatch])

  useEffect(() => {
    dispatch(getExpenseList())
  }, [dispatch])
  useEffect(() => {
    if (validation.values.service_ticket_id) {
      dispatch(getTicketDetail(validation.values.service_ticket_id))
    }
  }, [validation.values.service_ticket_id])

  const [externalCompanyList, setExternalCompanyList] = useState([])

  const fetchCompaniesByProject = async project_id => {
    try {
      const response = await getExternalCompanyByProject(project_id)
      setExternalCompanyList(response.companies)
    } catch (error) {
      console.error("Error fetching companies:", error)
      // Optionally, handle the error (e.g., show an error message to the user)
    }
  }

  useEffect(() => {
    if (validation.values.project_id) {
      fetchCompaniesByProject(validation.values.project_id)
    }
  }, [validation.values.project_id])

  console.log(validation.values)
  return (
    <React.Fragment>
      <div className="page-content ">
        <Container fluid={true}>
          <Form>
            <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
              <div className="d-flex gap-2 ">
                <i
                  className="mdi mdi-arrow-left font-size-20"
                  style={{ color: "grey" }}
                  onClick={() => {
                    navigate(-1)
                  }}
                ></i>
                Upload Proposal
              </div>
            </CardTitle>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label>Proposal No</Label>
                          <Input
                            className={`form-control ${
                              validation.touched.proposal_no &&
                              validation.errors.proposal_no
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            placeholder="Add Proposal No"
                            name="proposal_no"
                            value={validation.values.proposal_no || ""}
                          />
                          {validation.touched.proposal_no &&
                            validation.errors.proposal_no && (
                              <div className="invalid-feedback">
                                {validation.errors.proposal_no}
                              </div>
                            )}
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Label>Proposal date</Label>
                          <Input
                            className={`form-control ${
                              validation.touched.proposal_date &&
                              validation.errors.proposal_date
                                ? "is-invalid"
                                : ""
                            }`}
                            type="date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            placeholder="Add Proposal Date"
                            name="proposal_date"
                            value={validation.values.proposal_date || ""}
                          />
                          {validation.touched.proposal_date &&
                            validation.errors.proposal_date && (
                              <div className="invalid-feedback">
                                {validation.errors.proposal_date}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label>Goods & Services Category*</Label>

                          <select
                            className={`form-select ${
                              validation.touched.proposal_type_id &&
                              validation.errors.proposal_type_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="proposal_type_id"
                            value={validation.values.proposal_type_id || ""}
                          >
                            <option value="">
                              Select Goods & Services Category
                            </option>
                            {(proposalTypeList || []).map(proposal => (
                              <option key={proposal.id} value={proposal.id}>
                                {proposal.proposal_type}
                              </option>
                            ))}
                          </select>
                          {validation.touched.proposal_type_id &&
                            validation.errors.proposal_type_id && (
                              <div className="invalid-feedback">
                                {validation.errors.proposal_type_id}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label>Proposal Purpose</Label>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label>Project</Label>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label>Company</Label>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label>Proposal File</Label>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Input
                            type="text"
                            className={`form-control ${
                              validation.touched.proposal_title &&
                              validation.errors.proposal_title
                                ? "is-invalid"
                                : ""
                            }`}
                            id="proposal_title"
                            name="proposal_title"
                            placeholder="Enter Proposal Purpose"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.proposal_title || ""}
                          />
                          {validation.touched.proposal_title &&
                            validation.errors.proposal_title && (
                              <FormFeedback type="invalid">
                                {validation.errors.proposal_title}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.project_id &&
                              validation.errors.project_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="project_id"
                            value={validation.values.project_id || ""}
                          >
                            <option value="">Select Project</option>
                            {(projectList || []).map(project => (
                              <option key={project.id} value={project.id}>
                                {project.project_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.project_id &&
                            validation.errors.project_id && (
                              <div className="invalid-feedback">
                                {validation.errors.project_id}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.company_id &&
                              validation.errors.company_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="company_id"
                            value={validation.values.company_id || ""}
                          >
                            <option value="">Select Company</option>
                            {(externalCompanyList || []).map(company => (
                              <option key={company.id} value={company.id}>
                                {company.name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.company_id &&
                            validation.errors.company_id && (
                              <div className="invalid-feedback">
                                {validation.errors.company_id}
                              </div>
                            )}
                        </div>
                      </Col>
                      

                      <Col>
                        <div className="mb-3">
                          <Input
                            type="file"
                            id="proposal_file_path"
                            name="proposal_file_path"
                            onChange={e => {
                              const selectedFile = e.target.files[0]
                              validation.setFieldValue(
                                `proposal_file_path`,
                                selectedFile
                              )
                            }}
                            className={`form-control ${
                              validation.touched.proposal_file_path &&
                              validation.errors.proposal_file_path
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Add Proposal File"
                            onBlur={validation.handleBlur}
                          />
                        </div>
                        {validation.touched.proposal_file_path &&
                          validation.errors.proposal_file_path && (
                            <div className="invalid-feedback">
                              {validation.errors.proposal_file_path}
                            </div>
                          )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-3">Proposal Details</CardTitle>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label>Taxable Amount</Label>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label>GST Rate(%)</Label>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label>GST Amount</Label>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label>Total Amount</Label>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Input
                            type="text"
                            className={`form-control ${
                              validation.touched.taxable_amount &&
                              validation.errors.taxable_amount
                                ? "is-invalid"
                                : ""
                            }`}
                            id="taxable_amount"
                            name="taxable_amount"
                            placeholder="Enter Amount"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.taxable_amount || ""}
                          />
                          {validation.touched.taxable_amount &&
                            validation.errors.taxable_amount && (
                              <FormFeedback type="invalid">
                                {validation.errors.taxable_amount}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.gst_percentage &&
                              validation.errors.gst_percentage
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="gst_percentage"
                            value={validation.values.gst_percentage || ""}
                          >
                            <option value="">Select Gst Rate</option>
                            <option value="0">0</option>
                            {(gstRateList || []).map(gst => (
                              <option key={gst.id} value={gst.applicable_rate}>
                                {gst.applicable_rate}
                              </option>
                            ))}
                          </select>
                          {validation.touched.gst_percentage &&
                            validation.errors.gst_percentage && (
                              <div className="invalid-feedback">
                                {validation.errors.gst_percentage}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Input
                            type="text"
                            id="amount"
                            name="amount"
                            disabled
                            // onChange={validation.handleChange}
                            // onBlur={validation.handleBlur}
                            value={
                              (parseFloat(validation.values.taxable_amount) *
                                parseFloat(validation.values.gst_percentage)) /
                                100 || 0
                            }
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Input
                            type="text"
                            id="total"
                            name="total"
                            disabled
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              parseFloat(validation.values.taxable_amount) +
                                (parseFloat(validation.values.taxable_amount) *
                                  parseFloat(
                                    validation.values.gst_percentage
                                  )) /
                                  100 || 0
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button
                    type="button"
                    className="btn btn-primary w-md me-4"
                    style={{ width: "10%" }}
                    onClick={validation.handleSubmit}
                  >
                    Submit
                  </button>
                </div>
                .
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddProposal)

AddProposal.propTypes = {
  history: Proptypes.object,
}
