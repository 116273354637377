import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { Card, CardBody, Col, Row } from "reactstrap"
import SimpleBar from "simplebar-react"
import LargeModal5 from "../EditComponents/ProposalModal"
import LargeModal from "../EditComponents/uploadProgressModal"

//import images
// import adobephotoshop from "../../../assets/images/companies/adobe-photoshop.svg";

const Overview = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const [ticketId, setTicketId] = useState()
  const path = useLocation()
  useEffect(() => {
    const pathName = path.pathname
    const ticketId = pathName.split("/view-ticket/")[1]
    setTicketId(ticketId)
  }, [path])

  const ticketViewDetailState = state =>
    state.ServiceTicket.serviceTicketDetail.service_request_ticket
  const ticketData = useSelector(ticketViewDetailState) || []
  const serviceRequestDetailState = state =>
    state.ServiceTicket.serviceTicketDetail.service_request
  const serviceRequestData = useSelector(serviceRequestDetailState) || []

  const ticketLogState = state =>
    state.ServiceTicket.serviceTicketDetail.service_request_ticket_log
  const trackingData = useSelector(ticketLogState) || []
  console.log(trackingData)

  const [modalLarge5, setModalLarge5] = useState(false)
  const toggleModalLarge5 = () => setModalLarge5(!modalLarge5)

  const [modalLarge7, setModalLarge7] = useState(false)
  const toggleModalLarge7 = () => setModalLarge7(!modalLarge7)

  useEffect(() => {
    const pathName = path.pathname
    const ticketId = pathName.split("/view-ticket/")[1]
    setTicketId(ticketId)
  }, [path])
  return (
    <React.Fragment>
      <LargeModal
        isOpen={modalLarge7}
        toggle={toggleModalLarge7}
        id={ticketId}
      />
      <LargeModal5
        isOpen={modalLarge5}
        toggle={toggleModalLarge5}
        id={ticketId}
      />
      <Col xl={3}>
        <Card>
          <CardBody>
            <h5 className="fw-semibold">Ticket Summary</h5>

            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">Client Name</th>
                    <td scope="col">
                      {serviceRequestData && serviceRequestData.customer_name}
                    </td>
                  </tr>
                  {serviceRequestData && serviceRequestData.project_name && (
                    <tr>
                      <th scope="row">Project:</th>
                      <td>
                        {serviceRequestData && serviceRequestData.project_name}
                      </td>
                    </tr>
                  )}
                  {serviceRequestData && serviceRequestData.unit_code && (
                    <tr>
                      <th scope="row">Project Unit</th>
                      <td>
                        {serviceRequestData && serviceRequestData.unit_code}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th scope="row">Project Manager</th>
                    <td>{ticketData && ticketData.project_manager_name}</td>
                  </tr>
                  <tr>
                    <th scope="row">Ticket Supervisor</th>
                    <td>{ticketData && ticketData.ticket_supervisor_name}</td>
                  </tr>
                  <tr>
                    <th scope="row">Mode Of Operation</th>
                    <td>
                      {ticketData && ticketData.mode_of_operation === 1
                        ? "Internal"
                        : "External"}
                    </td>
                  </tr>
                  {ticketData && ticketData.mode_of_operation === 1 && (
                    <tr>
                      <th scope="row">Assigned User</th>
                      <td>{ticketData && ticketData.assigned_user_name}</td>
                    </tr>
                  )}
                  {ticketData && ticketData.mode_of_operation === 2 && (
                    <tr>
                      <th scope="row">Assigned Vendor</th>
                      <td>{ticketData && ticketData.vendor_name}</td>
                    </tr>
                  )}
                  {ticketData && ticketData.vendor_code && (
                    <tr>
                      <th scope="row">Assigned Vendor Code</th>
                      <td>{ticketData && ticketData.vendor_code}</td>
                    </tr>
                  )}
                  <tr>
                    <th scope="row">Priority</th>
                    <td>
                      <span
                        className={`badge ${
                          ticketData && ticketData.priority_id === 1
                            ? "badge-soft-danger"
                            : ticketData.priority_id === 2
                            ? "badge-soft-warning"
                            : ticketData.priority_id === 3
                            ? "badge-soft-info"
                            : ticketData.priority_id === 4
                            ? "badge-soft-success"
                            : "badge-soft-light"
                        }`}
                      >
                        {ticketData && ticketData.priority_id === 1
                          ? "Critical"
                          : ticketData.priority_id === 2
                          ? "High"
                          : ticketData.priority_id === 3
                          ? "Medium"
                          : ticketData.priority_id === 4
                          ? "Low"
                          : "NA"}
                      </span>{" "}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">Ticket Status</th>
                    <td>
                      <span className="badge badge-soft-success">
                        {ticketData && ticketData.ticket_current_status_name}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">Ticket Raised On</th>
                    <td>{ticketData && ticketData.formatted_created_at}</td>
                  </tr>
                  <tr>
                    <th scope="row">Last Updated Date</th>
                    <td>{ticketData && ticketData.formatted_updated_at}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="fw-semibold mb-0">Current Progress</h5>

              {ticketData &&
                ticketData.ticket_current_status_id === 4 &&
                ticketData.vendor_id === user.id && (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={toggleModalLarge7}
                  >
                    Add Attachment
                  </button>
                )}
            </div>
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  {ticketData &&
                    ticketData.work_in_progress_images &&
                    ticketData.work_in_progress_images.map((image, index) => (
                      <tr key={index}>
                        <th scope="col">{image.formatted_created_at}</th>
                        <td scope="col">
                          <a
                            href={image.file} // Assuming `file_url` is the field where the file's path is stored
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={image.file} // Use a thumbnail or the image URL directly
                              alt={`Work Progress Image ${index + 1}`}
                              style={{ width: "30px", height: "auto" }} // Adjust width and height as needed
                            />
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
        {ticketData &&
          (ticketData.ticket_current_status_id === 2 ||
            ticketData.ticket_current_status_id === 6 ||
            ticketData.ticket_current_status_id === 10 ||
            ticketData.ticket_current_status_id === 11 ||
            ticketData.ticket_current_status_id === 8) && (
            <Card>
              <CardBody>
                <Row>
                  <button
                    className=" btn btn-success"
                    onClick={toggleModalLarge5}
                  >
                    Add Proposal
                  </button>
                </Row>
              </CardBody>
            </Card>
          )}
        {ticketData && ticketData?.ticket_p_o?.proposal_file_path && (
          <Card>
            <CardBody>
              <Row>
                <tr>
                  <th scope="row">Proposal File</th>
                  <td>
                    <button
                      className=" btn btn-primary ms-2"
                      onClick={() => {
                        const proposalFilePath =
                          ticketData?.ticket_p_o?.proposal_file_path
                        if (proposalFilePath) {
                          window.open(proposalFilePath, "_blank")
                        }
                      }}
                    >
                      View Proposal
                    </button>
                    {/* <a href={ticketData?.ticket_p_o?.proposal_file_path} target="_blank" rel="noopener noreferrer">
                    {ticketData?.ticket_p_o?.proposal_file_path}
                  </a> */}
                  </td>
                </tr>
              </Row>
            </CardBody>
          </Card>
        )}
      </Col>
    </React.Fragment>
  )
}

export default Overview
